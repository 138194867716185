import React from 'react';
import {AuthUserContext} from "../Session";
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {Button, Icon} from "semantic-ui-react";
import SignOut from "../SignOut";

const Footer = () => (
    <div>
    <div style={container}>
        <div style={left}>Copyright 2015-2022 Xaver Roser</div>
        <div style={right}><Link to={ROUTES.LINKS}>Links</Link></div>
    </div>
        <div style={left}>
            <AuthUserContext.Consumer>
            {authUser => authUser ? <NavigationAuth/> : <NavigationNonAuth/>}
            </AuthUserContext.Consumer>
        </div>
    </div>
    )
;

const NavigationAuth = () => (
    <div>
        <div style={firstButton}>
            <Link to={ROUTES.ADMIN}><Button icon labelPosition={'left'}>Bilder hinzufügen<Icon name={'images outline'}/></Button></Link>
        </div>
        <div style={secondButton}>
            <SignOut/>
        </div>
    </div>
);

const secondButton = {
    marginLeft: '200px'
};

const firstButton = {
    float: 'left'
};

const container = {
    width: '100%',
    height: '30px'
};

const left = {
    float: 'left'
};

const right = {
    float: 'right',
};

const NavigationNonAuth = () => (
    <div style={right}>
        <Link to={ROUTES.SIGNIN}><Button icon labelPosition='left'>Anmelden<Icon name='lock'/></Button></Link>
    </div>
);


export default Footer;
