import React from "react";
import {Icon, Image, Modal} from "semantic-ui-react";

export class Slideshow extends React.Component {
    render() {
        return <Modal
                closeIcon
                style={{width: '90%', color: '#ffffff'}}
                open={this.props.isOpen}
                onClose={() => this.onClose()}>
            <Modal.Content style={{background: 'rgba(0,0,0,.85)'}} image>
                <div style={{fontSize: '20px', margin: 'auto', paddingRight: '20px'}}><Icon name='left arrow' onClick={() => this.previousImage()}/></div>
                <Image style={{margin: 'auto'}} src={this.props.imageSource}/>
                <div style={{fontSize: '20px', margin: 'auto', paddingLeft: '20px'}}><Icon name='right arrow' onClick={() => this.nextImage()}/></div>
            </Modal.Content>
            <Modal.Actions style={{background: 'rgba(0,0,0,.85)'}}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{this.props.imageTitle}</div>
            </Modal.Actions>
        </Modal>
    }

    componentDidMount() {
        window.addEventListener("keyup", this.handleKeyEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyEvent);
    }

    handleKeyEvent = (event) => {
        if (event.key === 'ArrowLeft') {
            this.previousImage();
        } else if (event.key === 'ArrowRight') {
            this.nextImage();
        }
        event.stopPropagation();
    }

    onClose() {
        this.props.onClose();
    }

    previousImage() {
        this.props.previousImage();
    }

    nextImage() {
        this.props.nextImage();
    }

}
