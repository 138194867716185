export const HOME = '/';
export const ADMIN = '/admin';
export const SIGNIN = '/signin';
export const SIGNOUT = '/signout';
export const ANIMALS = '/animals';
export const LANDSCAPES = '/landscapes';
export const PLANTS = '/plants';
export const TRIPS = '/trips';
export const PANORAMAS = '/panoramas';
export const LINKS = '/links';
