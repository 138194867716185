import React from 'react';
import {Grid, Image} from 'semantic-ui-react'
import animalsImage from '../../images/tiere.jpg';
import plantsImage from '../../images/pflanzen.jpg';
import landscapesImage from '../../images/landschaften.jpg';
import tripsImage from '../../images/reisen.jpg';
import panoramasImage from '../../images/panorama.jpg';
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const Home = () => (
    <div className='thumbsContainer'>
        <Grid className='thumbs'>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Link to={ROUTES.ANIMALS}>
                        <div className='imageThumb'>
                            <Image src={animalsImage}/>
                            <div className='imageText'>Tiere</div>
                        </div>
                    </Link>
                </Grid.Column>
                <Grid.Column>
                    <Link to={ROUTES.PLANTS}>
                        <div className='imageThumb'>
                            <Image src={plantsImage}/>
                            <div className='imageText'>Pflanzen</div>
                        </div>
                    </Link>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Link to={ROUTES.LANDSCAPES}>
                        <div className='imageThumb'>
                            <Image src={landscapesImage}/>
                            <div className='imageText'>Landschaften</div>
                        </div>
                    </Link>
                </Grid.Column>
                <Grid.Column>
                    <Link to={ROUTES.TRIPS}>
                        <div className='imageThumb'>
                            <Image src={tripsImage}/>
                            <div className='imageText'>Reisen</div>
                        </div>
                    </Link>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
                <Grid.Column>
                    <Link to={ROUTES.PANORAMAS}>
                        <div className='imageThumb'>
                            <Image src={panoramasImage}/>
                            <div className='imageText'>Panorama</div>
                        </div>
                    </Link>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
);

export default Home;