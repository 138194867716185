import React, {Component} from 'react';
import {withAuthorization} from '../Session';
import {withFirebase} from "../Firebase";
import FileUploader from "react-firebase-file-uploader";
import {Button, Dropdown, Icon, Input, Progress, Table} from "semantic-ui-react";

class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploadingFiles: [],
            newFiles: [],
            progress: {},
            categoryOptions: [],
            isUploading: false,
            isDataComplete: false
        };
    }

    componentDidMount() {
        let unsubscribe = this.props.firebase.allImages().onSnapshot(this.onUpdate);
        this.setState({unsubscribe: unsubscribe});
    }

    componentWillUnmount() {
        this.state.unsubscribe();
    }

    onUpdate = (querySnapshot) => {
        let images = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const image = {
                uid: doc.id,
                title: data.title,
                url: data.image,
                category: data.category,
                order: data.order,
                page: data.page,
                editable: false,
            };
            images.push(image);
        });

        this.setState({images: images});

        let categories = [...new Set(images.map(item => item.category))];
        let pages = [...new Set(images.map(item => item.page))];

        let categoryOptions = [];
        let pageOptions = [];
        for (let cat of categories) {
            categoryOptions.push({key: cat, text: cat, value: cat});
        }
        for (let page of pages) {
            pageOptions.push({key: page, text: page, value: page});
        }
        this.setState({categoryOptions: categoryOptions, pageOptions: pageOptions});
    };

    centerStyle = {
        textAlign: 'center'
    };

    render() {
        return (
            <div style={this.centerStyle}>
                <label className={'ui icon left labeled button'}>
                    Neue Bilder auswählen
                    <i aria-hidden="true" className="images outline icon"></i>
                    <FileUploader
                        hidden
                        multiple
                        disabled={this.state.isUploading}
                        accept="image/*"
                        storageRef={this.props.firebase.imageStore()}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                    />
                </label>
                <div className={this.state.uploadingFiles.length <= 0 ? 'uploadDisabled uploadPane': 'uploadPane'}>
                    <Table celled fixed>
                        {this.state.uploadingFiles.length > 0 ?    <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Datei</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Titel</Table.HeaderCell>
                                <Table.HeaderCell>Seite</Table.HeaderCell>
                                <Table.HeaderCell>Kategorie</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header> : null}
                        <Table.Body>
                        {this.state.uploadingFiles.map(uploadedFile => (
                            <Table.Row key={uploadedFile.name}>
                                <Table.Cell>{uploadedFile.name}</Table.Cell>
                                <Table.Cell><Progress className={'progressBar'} percent={this.state.progress[uploadedFile.name]} autoSuccess progress/></Table.Cell>
                                <Table.Cell style={{overflow: 'visible'}}><Input onChange={(e, data) => this.handleTitleChange(e, data, uploadedFile.name)} placeholder={uploadedFile.name}/></Table.Cell>
                                <Table.Cell style={{overflow: 'visible'}}>
                                    <Dropdown
                                        placeholder='Seite auswählen'
                                        fluid
                                        selection
                                        onChange={(e, data) => this.handlePageChange(e, data, uploadedFile.name)}
                                        options={this.state.pageOptions}
                                    />
                                </Table.Cell>
                                <Table.Cell style={{overflow: 'visible'}}>
                                    <Dropdown
                                        placeholder='Kategorie auswählen'
                                        fluid
                                        allowAdditions
                                        search
                                        selection
                                        onChange={(e, data) => this.handleCategoryChange(e, data, uploadedFile.name)}
                                        options={this.state.categoryOptions}
                                        onAddItem={this.handleAddition}
                                    />
                                </Table.Cell>
                            </Table.Row>))
                        }
                        </Table.Body>
                    </Table>
                    {this.state.uploadingFiles.length > 0 ? <Button disabled={!this.state.isDataComplete} icon labelPosition={'left'} onClick={this.save}>Speichern<Icon name={'save outline'}/></Button> : null}
                </div>
            </div>
        );
    }

    handleAddition = (e, { value }) => {
        this.setState(prevState => ({
            categoryOptions: [{ text: value, key: value, value: value }, ...prevState.categoryOptions],
        }))
    };

    save = () => {
        this.setState({isDataComplete: false});
        let order = Math.max.apply(Math, this.state.images.map(function(img) { return img.order; }))

        this.state.newFiles.forEach(image => {
            image['order'] = ++order;
        });

        for (let image of this.state.newFiles) {
            this.props.firebase.allImages().add({category: image.category, title: image.title, image: image.image, order: image.order, page: image.page});
        }
        this.setState({uploadingFiles: [], newFiles: [], isUploading: false})
    };

    handleTitleChange = (_, data, file) => {
        let img = this.state.newFiles.find(function(element) {
            return element.id === file.substring(file.indexOf('/') + 1);
        });
        img['title'] = data.value;
        this.checkForCompletion();
    };

    checkForCompletion() {
        let complete = true;

        this.state.newFiles.forEach(image => {
           if (image.title === undefined) {
               complete = false;
           }
           if (image.image === undefined) {
               complete = false;
           }
           if (image.page === undefined) {
               complete = false;
           }
           if (image.category === undefined) {
               complete = false;
           }
        });

        if (complete) {
            this.setState({isDataComplete: true});
        }

    }

    handlePageChange = (_, data, file) => {
        let img = this.state.newFiles.find(function(element) {
            return element.id === file.substring(file.indexOf('/') + 1);
        });
        img['page'] = data.value;
        this.checkForCompletion();
    };

    handleCategoryChange = (_, data, file) => {
        let img = this.state.newFiles.find(function(element) {
            return element.id === file.substring(file.indexOf('/') + 1);
        });
        img['category'] = data.value;
        this.checkForCompletion();
    };

    handleUploadStart = (file, task) => {
        let files = this.state.uploadingFiles;
        files.push(file);
        this.setState({uploadingFiles: files});
        this.setState({isUploading: true});

        let newFiles = this.state.newFiles;
        let id = file.name;
        newFiles.push({id: id, title: id});
        this.setState({newFiles: newFiles});
    };

    handleProgress = (progress, task) => {
        var self = this;
        let id = task._delegate.then(function(result) {
            id = result.metadata.name;
            let progressObj = self.state.progress;
            progressObj[id] = progress;
            self.setState({progress: progressObj});
        }, function(err) {
            self.setState({isUploading: false});
            console.log(err);
        });
    };

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        this.props.firebase.imageStore()
            .child(filename)
            .getDownloadURL()
            .then(url => {
                let file = this.state.newFiles.find(function(element) {
                    return element.id === filename;
                });
                file['image'] = url;
                this.checkForCompletion();
            });
    };

}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(Admin));
