import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

const Navigation = () => (
    <div>
        <Link to={ROUTES.HOME}>
            <div className='title'>
                <div style={{marginBottom: '15px'}}>
                    <span className="caption">XAVER|ROSER</span>
                </div>
                <div>
                    <span className="subcaption">Naturfotografie</span>
                </div>
            </div>
        </Link>
    </div>
);

export default Navigation;
