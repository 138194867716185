import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Gallery from '../Gallery/index';

class Landscapes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            categories: [],
            categoryOptions: []
        };
    }

    componentDidMount() {
        let unsubscribe = this.props.firebase.allImages().where("page", "==", "Landschaften").onSnapshot(this.onUpdate);
        this.setState({unsubscribe: unsubscribe});
    }

    componentWillUnmount() {
        this.state.unsubscribe();
    }

    onUpdate = (querySnapshot) => {
        let images = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const image = {
                uid: doc.id,
                title: data.title,
                url: data.image,
                category: data.category,
                order: data.order,
                page: data.page,
                editable: false,
            };
            images.push(image);
        });

        images = images.sort((a, b) => a.order - b.order);
        this.setState({images: images});
        let categories = [...new Set(images.map(item => item.category))];
        this.setState({categories: categories});

        let categoryOptions = [];
        for (let cat of this.state.categories) {
            categoryOptions.push({key: cat, text: cat, value: cat});
        }
        this.setState({categoryOptions: categoryOptions});
    };

    render() {
        return (
            <Gallery images={this.state.images} categories={this.state.categories} categoryOptions={this.state.categoryOptions}/>
        );
    }

}

export default withFirebase(Landscapes);
