import React from "react";
import {Label} from "semantic-ui-react";

export class GalleryNavigation extends React.Component {
    render() {
        return <div style={{display: 'inline'}}>
            {
                this.props.categories.map(category => (
                    <Label
                        className={this.props.selectedCategory === category ? 'categoryLabel selectedLabel' : 'categoryLabel unselectedLabel'}
                        onClick={() => this.changeCategory(category)} key={category}>{category}
                        <Label.Detail>{this.props.images.filter(image => {
                            return image.category === category
                        }).length}</Label.Detail>
                    </Label>
                ))
            }</div>
    }

    changeCategory(category) {
        this.props.onCategoryChange(category);
    }
}
