import React, {Component} from 'react';
import {withFirebase} from '../Firebase';
import {Button, Dropdown, Icon, Image, Input, Label, Reveal} from "semantic-ui-react";
import {AuthUserContext} from "../Session";
import {BeatLoader} from "react-spinners";
import {GalleryNavigation} from "./GalleryNavigation";
import {GalleryAdminNavigation} from "./GalleryAdminNavigation";
import {Slideshow} from "./Slideshow";

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.onCloseSlideshow = this.onCloseSlideshow.bind(this);
        this.previousImage = this.previousImage.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.onCloseSlideshow = this.onCloseSlideshow.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);

        this.state = {
            selectedCategory: 'all',
            photoIndex: 0,
            isOpen: false,
            changed: false,
            numberLoaded: 0,
            loaded: false,
            categories: []
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.categories !== this.props.categories){
            this.setState({ categories: nextProps.categories })
        }
    }

    render() {
        return (
            <div>
                <Slideshow imageTitle={this.state.selectedImage ? this.state.selectedImage.title : ''} imageSource={this.state.selectedImage ? this.state.selectedImage.url : ''} onClose={this.onCloseSlideshow} isOpen={this.state.isOpen} nextImage={this.nextImage} previousImage={this.previousImage} />
                <Label
                    className={this.state.selectedCategory === 'all' ? 'categoryLabel selectedLabel' : 'categoryLabel unselectedLabel'}
                    onClick={() => this.handleClick('all')}>
                    Alle
                    <Label.Detail>{this.props.images.length}</Label.Detail>
                </Label>
                <AuthUserContext.Consumer>
                    {authUser => {
                        if (authUser) {
                            return <GalleryAdminNavigation
                                onCategoryChange={this.handleClick}
                                moveUp={this.moveUp}
                                moveDown={this.moveDown}
                                images={this.props.images}
                                categories={this.state.categories}
                                selectedCategory={this.state.selectedCategory}/>
                        } else {
                            return <GalleryNavigation
                                onCategoryChange={this.handleClick}
                                images={this.props.images}
                                categories={this.state.categories}
                                selectedCategory={this.state.selectedCategory}/>
                        }
                    }}
                </AuthUserContext.Consumer>
                <hr/>

                <div className={'imagesContainer'}>
                    {this.props.images.filter(img => !img.isDeleted).sort((a, b) => a.order - b.order).map(image => (
                        <div key={image.uid} onClick={() => {
                            this.setState({isOpen: true, selectedImage: image});
                            this.props.firebase.updateCounter(image.uid);
                        }}
                             className={image.category === this.state.selectedCategory || this.state.selectedCategory === 'all' ? (this.props.page === 'Panorama' ? 'imageContainerPanorama' : 'imageContainer') : (this.props.page === 'Panorama' ? 'imageContainerPanorama notSelected' : 'imageContainer notSelected')}>
                            <Reveal animated='small fade'>
                                <Reveal.Content visible style={{height: '100%'}}>
                                    <Image src={image.url}
                                           style={{height: '100%'}}
                                           onLoad={this.handleImageLoaded.bind(this)}
                                           onError={this.handleImageErrored.bind(this)}/>
                                    <AuthUserContext.Consumer>
                                        {authUser => {
                                            if (authUser && this.state.selectedCategory === 'all') {
                                                return <div className={'adminButtons'}>
                                                    <Button icon onClick={(e) => this.handleDeleteClick(e, image)}><Icon
                                                        name={'trash'}/></Button>
                                                    <Button icon onClick={(e) => this.handleEditClick(e, image)}><Icon
                                                        name={'edit'}/></Button>
                                                    {image.order === 1 ? null : <Button icon
                                                                                        onClick={(e) => this.handleLeftClick(e, image)}><Icon
                                                        name={'left arrow'}/></Button>}
                                                    {image.order === Math.max(...this.props.images.map(o => o.order), 0) ? null :
                                                        <Button icon
                                                                onClick={(e) => this.handleRightClick(e, image)}><Icon
                                                            name={'right arrow'}/></Button>}
                                                    {image.order === 1 || image.order === 2 || image.order === 3 ? null :
                                                        <Button icon onClick={(e) => this.handleUpClick(e, image)}><Icon
                                                            name={'arrow up'}/></Button>}
                                                    {image.order > Math.max(...this.props.images.map(o => o.order), 0) - 3 ? null :
                                                        <Button onClick={(e) => this.handleDownClick(e, image)}
                                                                icon><Icon name={'arrow down'}/></Button>}
                                                    {image.editable ?
                                                        <div className='editPane'><Dropdown allowAdditions search
                                                                                            selection fluid
                                                                                            value={image.category}
                                                                                            onAddItem={(e, data) => this.handleAddition(e, data, image)}
                                                                                            onChange={(e, data) => this.handleCategoryChange(e, data, image)}
                                                                                            options={this.props.categoryOptions}></Dropdown><Input
                                                            className={'titleInput'}
                                                            onBlur={(e) => this.handleBlur(e, image)}
                                                            onClick={this.handleEditInputClick}
                                                            placeholder={image.title}/></div> : null}
                                                </div>
                                            }
                                        }}
                                    </AuthUserContext.Consumer>
                                </Reveal.Content>
                                <Reveal.Content hidden>
                                    <div
                                        className={this.props.page === 'Panorama' ? 'imageHoverPanorama' : 'imageHover'}>
                                        <BeatLoader color={'#000000'}
                                                    loading={!this.state.loaded} size={16}/>
                                    </div>
                                    {this.state.loaded ? <div
                                        className={this.props.page === 'Panorama' ? 'imageHoverPanorama' : 'imageHover'}>{image.title}</div> : ''}
                                </Reveal.Content>
                            </Reveal>
                        </div>
                    ))}
                </div>
                <AuthUserContext.Consumer>
                    {authUser => {
                        if (authUser) {
                            return <div>
                                {this.state.changed ?
                                    <Button onClick={() => this.handleSave()} icon labelPosition={'left'}>Speichern<Icon
                                        name={'save outline'}/></Button> : null}
                            </div>
                        }
                    }
                    }
                </AuthUserContext.Consumer>
            </div>
        );
    }

    handleAddition = (e, data, image) => {
        image.category = data.value;
        let options = this.props.categoryOptions;
        options.push({text: data.value, key: data.value, value: data.value});
        this.setState({changed: true, categoryOptions: options});
    };

    addCategory() {
        this.setState({changed: true});
    }

    handleCategoryChange(_, data, image) {
        image.category = data.value;
        this.setState({changed: true});
    }

    handleClick(category) {
        this.setState({selectedCategory: category})
    }

    nextImage() {
        let index = this.props.images.indexOf(this.state.selectedImage);
        index++;
        if (index > this.props.images.length - 1) {
            index = 0;
        }

        if (this.state.selectedCategory !== 'all') {
            while (this.props.images[index].category !== this.state.selectedCategory) {
                index++;
                if (index > this.props.images.length - 1) {
                    index = 0;
                }
            }
        }
        let newImage = this.props.images[index];
        this.setState({selectedImage: newImage});
    }

    previousImage() {
        let index = this.props.images.indexOf(this.state.selectedImage);
        index--;
        if (index < 0) {
            index = this.props.images.length - 1;
        }

        if (this.state.selectedCategory !== 'all') {
            while (this.props.images[index].category !== this.state.selectedCategory) {
                index--;
                if (index < 0) {
                    index = this.props.images.length - 1;
                }
            }
        }

        let newImage = this.props.images[index];
        this.setState({selectedImage: newImage});
    }

    handleDeleteClick(e, image) {
        e.stopPropagation();

        image.isDeleted = true;
        let imgs = this.props.images;
        imgs = imgs.filter(function (item) {
            return item.uid !== image.uid;
        });

        let newOrder = 1;
        for (let img of imgs) {
            img.order = newOrder;
            newOrder++;
        }
        this.setState({images: imgs});
        this.setState({changed: true});
    }

    handleUpClick(e, image) {
        e.stopPropagation();

        let position = this.props.images.indexOf(image);
        let imgs = this.props.images;

        this.swapArrayElements(imgs, position, position - 3);

        let newOrder = 1;
        for (let img of imgs) {
            img.order = newOrder;
            newOrder++;
        }

        this.setState({images: imgs});
        this.setState({changed: true});
    }

    handleDownClick(e, image) {
        e.stopPropagation();

        let position = this.props.images.indexOf(image);
        let imgs = this.props.images;

        this.swapArrayElements(imgs, position, position + 3);

        let newOrder = 1;
        for (let img of imgs) {
            img.order = newOrder;
            newOrder++;
        }

        this.setState({images: imgs});
        this.setState({changed: true});
    }

    handleLeftClick(e, image) {
        e.stopPropagation();
        let position = this.props.images.indexOf(image);
        let imgs = this.props.images;

        this.swapArrayElements(imgs, position, position - 1);

        let newOrder = 1;
        for (let img of imgs) {
            img.order = newOrder;
            newOrder++;
        }

        this.setState({images: imgs});
        this.setState({changed: true});
    }

    swapArrayElements = function (arr, indexA, indexB) {
        let temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    };

    handleRightClick(e, image) {
        e.stopPropagation();
        let position = this.props.images.indexOf(image);
        let imgs = this.props.images;

        this.swapArrayElements(imgs, position, position + 1);

        let newOrder = 1;
        for (let img of imgs) {
            img.order = newOrder;
            newOrder++;
        }

        this.setState({images: imgs});
        this.setState({changed: true});
    }

    handleSave() {
        for (let image of this.props.images) {
            if (!image.isDeleted) {
                this.props.firebase.allImages().doc(image.uid).update({
                    category: image.category,
                    title: image.title,
                    image: image.url,
                    order: image.order,
                    page: image.page
                })
            } else {
                this.props.firebase.allImages().doc(image.uid).delete();
            }
        }
        this.setState({changed: false});
    }

    handleEditClick(e, image) {
        e.stopPropagation();
        image.editable = true;
        this.setState({changed: false});
    }

    handleEditInputClick(e) {
        e.stopPropagation();
    }

    handleBlur(e, image) {
        image.title = e.target.value;
        image.editable = false;
        this.setState({changed: true});
    }

    handleImageLoaded() {
        this.setState({numberLoaded: this.state.numberLoaded + 1},
            () => {
                if (this.state.numberLoaded === this.props.images.length) {
                    this.setState({loaded: true});
                }
            },
        );
    }

    handleImageErrored() {
        this.setState({numberLoaded: this.state.numberLoaded + 1},
            () => {
                if (this.state.numberLoaded === this.props.images.length) {
                    this.setState({loaded: true});
                }
            },
        );
    }

    onCloseSlideshow() {
        this.setState({isOpen: false})
    }

    moveUp(category) {
        let numberOfImagesWithThisCategory = this.props.images.filter(image => {
                return image.category === category
            }).length;

        let orderCounterOther = numberOfImagesWithThisCategory + 1;
        let orderCounter = 1;

        for (let image of this.props.images) {
            if (image.category === category) {
                image.order = orderCounter;
                orderCounter++;
            } else {
                image.order = orderCounterOther;
                orderCounterOther++;
            }
        }

        let newImages = this.props.images.sort((a, b) => a.order - b.order);
        let categories = [...new Set(newImages.map(item => item.category))];
        this.setState({ changed: true, categories: categories });
    }

    moveDown(category) {
        let orderCounter = 1;
        for (let image of this.props.images) {
            if (image.category !== category) {
                image.order = orderCounter++;
            }
        }
        for (let image of this.props.images) {
            if (image.category === category) {
                image.order = orderCounter++;
            }
        }
        let newImages = this.props.images.sort((a, b) => a.order - b.order);
        let categories = [...new Set(newImages.map(item => item.category))];
        this.setState({ changed: true, categories: categories });
    }
}

export default withFirebase(Gallery);
