import React from "react";
import {Icon, Label} from "semantic-ui-react";
import EditableLabel from "react-editable-label";

export class GalleryAdminNavigation extends React.Component {
    render() {
        return <div style={{display: 'inline'}}>
            <div style={{display: 'inline'}}>
                {
                    this.props.categories.map((category, index) => (
                        <div key={category} style={{paddingRight: '20px', display: 'inline'}}>
                            {this.props.selectedCategory === 'all' && index !== 0 && <Icon onClick={() => this.moveUp(category)} name={'angle double left'}/>}
                            <Label
                                className={this.props.selectedCategory === category ? 'categoryLabel selectedLabel' : 'categoryLabel unselectedLabel'}
                                onClick={() => this.changeCategory(category)} key={category}>{category}
                                <Label.Detail>{this.props.images.filter(image => {
                                    return image.category === category
                                }).length}</Label.Detail>
                            </Label>
                            {this.props.selectedCategory === 'all' && index !== this.props.categories.length - 1 && <Icon onClick={() => this.moveDown(category)} name={'angle double right'}/>}
                        </div>
                    ))
                }</div>
            <div className='ui label categoryLabel unselectedLabel'><EditableLabel
                labelClass='addNewCategory'
                initialValue='+'
                save={(value) => {
                    this.props.categories.push(value);
                    this.props.categoryOptions.push({key: value, value: value, text: value})
                    this.setState({changed: true})
                    setTimeout(() => {
                        let elms = document.getElementsByClassName('addNewCategory').item(0);
                        elms.textContent = '+'
                    }, 0);
                }}
            /></div>
        </div>
    }

    changeCategory(category) {
        this.props.onCategoryChange(category);
    }

    moveDown(category) {
        this.props.moveDown(category);
    }

    moveUp(category) {
        this.props.moveUp(category);
    }
}
