import firebase from 'firebase';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA95-WytvQ1xr9V3TefHmhjtdKk_3uGmBk",
    authDomain: "foto-roser-li.firebaseapp.com",
    databaseURL: "https://foto-roser-li.firebaseio.com",
    projectId: "foto-roser-li",
    storageBucket: "gs://foto-roser-li.appspot.com",
    messagingSenderId: "83488328543",
    appId: "1:83488328543:web:a8628a5c5c0207a0"
};

class Firebase {
    constructor() {
        app.initializeApp(firebaseConfig);
        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.realtimeDatabase = firebase.database();
    }
    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    allImages = () => this.db.collection('images');

    imageStore = () => this.storage.ref('images');

    updateCounter = (key) => this.realtimeDatabase.ref(key).set(firebase.database.ServerValue.increment(1)).catch(alert);

}

export default Firebase;
