import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignIn = () => (
    <div style={centerStyle}>
        <h1>Als Administrator anmelden</h1>
        <SignInForm />
    </div>
);

const centerStyle = {
    textAlign: 'center'
};

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.ADMIN);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div>
                    <div style={{marginBottom: '10px'}}>
                    <label style={{width: '80px', display: 'inline-block'}}>Email:</label>
                    <input style={{width: '200px'}}
                        name="email"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Email Adresse"
                    />
                    </div>
                    <div style={{marginBottom: '10px'}}>
                    <label style={{width: '80px', display: 'inline-block'}}>Passwort:</label>
                    <input style={{width: '200px'}}
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Passwort"
                    />
                    </div>
                </div>
                <button disabled={isInvalid} type="submit">
                    Anmelden
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

export default SignIn;

export { SignInForm };
