import React from 'react';
import {BrowserRouter as Router, Route,} from 'react-router-dom';
import Navigation from "../Navigation";
import * as ROUTES from '../../constants/routes';
import SignIn from "../SignIn";
import Admin from "../Admin";
import Home from "../Home";
import { withAuthentication} from '../Session';
import Footer from "../Footer";
import 'semantic-ui-css/semantic.min.css';
import Animals from "../Animals";
import Plants from "../Plants";
import Landscapes from "../Landscapes";
import Trips from "../Trips";
import Panoramas from "../Panoramas";
import Links from "../Links";

const App = () => (
    <Router>
        <div className='container'>
            <div className='header'>
                <Navigation/>
            </div>
            <div className='content'>
                <Route exact path={ROUTES.HOME} component={Home}/>
                <Route path={ROUTES.SIGNIN} component={SignIn}/>
                <Route path={ROUTES.ADMIN} component={Admin}/>
                <Route path={ROUTES.ANIMALS} component={Animals}/>
                <Route path={ROUTES.PLANTS} component={Plants}/>
                <Route path={ROUTES.LANDSCAPES} component={Landscapes}/>
                <Route path={ROUTES.TRIPS} component={Trips}/>
                <Route path={ROUTES.PANORAMAS} component={Panoramas}/>
                <Route path={ROUTES.LINKS} component={Links}/>
            </div>
            <div className='footer'>
                <hr/>
                <Footer/>
            </div>
        </div>
    </Router>
);

export default withAuthentication(App);
