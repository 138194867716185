import React, {Component} from 'react';
import {withFirebase} from '../Firebase';
import {Image} from "semantic-ui-react";
import ovluImage from '../../images/links/ovlu.png';
import fotoCommunityImage from '../../images/links/fotocommunity.png'
import spektralImage from '../../images/links/spektral.png'

class Links extends Component {

    render() {
        return (
            <div style={links}>
                <div style={linkContainer}>
                    <a href="http://www.spektral.li" target="_blank" rel="noopener noreferrer">
                        <Image style={linkImage} src={spektralImage}/></a>
                    <span style={linkText}>Fotoclub Spektral</span>
                </div>
                <div style={linkContainer}>
                    <a href="http://www.fotocommunity.de/pc/pc/mypics/531592" target="_blank" rel="noopener noreferrer">
                        <Image style={linkImage} src={fotoCommunityImage}/></a>
                    <span style={linkText}>Josef Heeb auf fotocommunity.de</span>
                </div>
                <div style={linkContainer}>
                    <a href="http://www.ovlu.li" target="_blank" rel="noopener noreferrer">
                        <Image style={linkImage} src={ovluImage}/></a>
                    <span style={linkText}>Ornithologischer Verein Liechtensteiner Unterland</span>
                </div>
            </div>
        );
    }
}

const links = {
    marginBottom: '10px',
    overflow: 'hidden'
}

const linkContainer = {
    width: '290px',
    float: 'left',
    margin: '20px',
    textAlign: 'center'
}

const linkImage = {
    width: '290px',
    height: '240px'
}

const linkText = {
    fontSize: '16px',
    marginTop: '5px',
    marginBottom: '10px'
}

export default withFirebase(Links);
